<template>
  <div>
    <el-dialog
        title="Хугацаа нь дуусч буй бараа"
        :visible.sync="show"
        width="700px"
        @close="closeFinishDialog">
        <el-col>
          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
            <el-button
              slot="prepend"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
      <el-table
        :data="products"
        v-loading='isLoading'
        style="width: 100%"
        height="500"
      >
        <el-table-column label="Зураг" align="center">
          <template slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="12">
                <div class="image-holder">
                  <img :src="scope.row.variant_image">
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="Нэр" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.variant_name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Нийлүүлэгч" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.supplier_name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Дуусах хугацаа" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.expired_date}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change = "curentPageHandler"
        @size-change = "sizeChangeHandler"
        class="text-right mt10"
        background
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">Болих</el-button>
      </span>
      </el-dialog>
  </div>
</template>

<script>
import services from '../../../../helpers/services'
export default {
  name: 'getExpiredProductDialog',
  props: [
    'visibleProductDialog'
  ],

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getExpiredVariants(from, size, this.search)
  },

  data () {
    return {
      // product_length: this.products.length,
      products: [],
      totalCount: 0,
      isLoading: false,
      show: this.visibleProductDialog,
      currentPage: 1,
      pageSize: 20,
      search: ''
    }
  },

  methods: {
    closeFinishDialog () {
      this.$emit('update:visibleProductDialog', false)
    },

    onSearch () {
      this.getExpiredVariants((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.getExpiredVariants((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'inventory', query: { page: item, size: this.pageSize } })
      this.getExpiredVariants((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    getExpiredVariants (from, size, search) {
      this.isLoading = true
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size
      services.getExpiredVariants(query).then(response => {
        this.products = response.data
        this.totalCount = response.total
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
</style>
