<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Бүтээгдэхүүний дэлгэрэнгүй</h2>
        </el-col>
        <el-col :span="12" class="text-right">
          <el-badge :value="expiredTotal" class="item">
            <el-button @click="visibleProductDialog = true" type="default">Хугацаа нь дуусч буй бараа</el-button>
          </el-badge>
        </el-col>
      </el-row>
    </header>
    <!-- Tabled content -->
    <div class="panel tabled">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Бүгд" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="12">
                  <el-input v-model="search" @input="onSearch" placeholder="Бараа хайх" class="input-with-select">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-input @input="maxMin" placeholder="min" v-model="mini"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-input @input="maxMin" placeholder="max" v-model="maxi"></el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort">
                    <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- Product list table ued zaaval product-table gesen class uguh -->
            <el-table
              v-loading="isLoading"
              class="product-table"
              size="mini"
              @cell-click="clickCell"
              @sort-change='handleSortTable'
              :data="tableData"
              style="width: 100%">
              <el-table-column
                header-align="center"
                label="Бүтээгдэхүүн"
                sortable
                width="300"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="Array.isArray(scope.row.variant_image) ? scope.row.variant_image[0] : scope.row.variant_image"
                          fit="contain"></el-image>
                    </el-col>
                    <el-col :span="18">
                        <strong>{{scope.row.name_mon}}</strong>
                      <br />
                        <span class="secondary">{{ mergeValues(scope.row.option) }}</span>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                prop="sell_check"
                sortable
                width="140"
                label="Дууссанч зарах">
                <template slot-scope="scope">
                  <span>{{scope.row.sell_check ? 'Тийм' : 'Үгүй'}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="SKU"
                width="160"
                prop="variant_sku">
              </el-table-column>
              <el-table-column
                label="Татаж буй"
                prop="transferCount"
                align="right">
                <template slot-scope="scope">
                  <span :class="scope.row.transferCount > 0 ? '' : 'text-danger'">{{scope.row.transferCount}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="availableCount"
                label="Боломжит"
                sortable
                width="110"
                align="center">
                <template slot-scope="scope">
                  <span :class="scope.row.availableCount > 0 ? '' : 'text-danger'">{{scope.row.availableCount}}</span>
                  <span v-if="scope.row.quantityChangeNumber !== undefined && scope.row.quantityChangeNumber != 0">
                    <strong class="mlr5">></strong>
                    <span v-if="scope.row.quantityChangeType == 'Add'" class="text-warning">{{scope.row.quantityChangeNumber + scope.row.availableCount}}</span>
                    <span v-if="scope.row.quantityChangeType == 'Set'" class="text-warning">{{scope.row.quantityChangeNumber}}</span>
                  </span>
                </template>
              </el-table-column>
               <el-table-column
                label="Хүлээгдэж буй"
                prop="lock_count"
                sortable
                align="center"
                width="130">
                <template slot-scope="scope">
                  <span :class="scope.row.lock_count ? '' : 'text-danger'">{{scope.row.lock_count ? scope.row.lock_count : 0}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change = "curentPageHandler"
              @size-change = "sizeChangeHandler"
              class="text-right mt10"
               background
               :current-page.sync="currentPage"
              :page-size="pageSize"
              :page-sizes="[5, 20, 50, 100]"
              layout="total, sizes, prev, pager, next"
              :total="totalCount">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <get-expired-product-dialog v-if="visibleProductDialog" :visible-product-dialog.sync="visibleProductDialog"></get-expired-product-dialog>
  </div>
</template>

<script>
import getExpiredProductDialog from './components/getExpiredProductDialog'
import services from '../../../helpers/services'
export default {
  name: 'stockList',
  components: {
    // MenuIcon
    getExpiredProductDialog
  },
  data () {
    return {
      expiredTotal: '',
      visibleProductDialog: false,
      sortName: 'Эрэмбэлэх',
      field: '',
      sort: '',
      maxi: '',
      mini: '',
      totalCount: 0,
      pageSize: 20,
      pageFrom: 0,
      currentPage: 1,
      search: '',
      quatityEditOption: 'Add',
      tableData: null,
      isLoading: false,
      activeTabName: 'all',
      dropdownDataFilter: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }, {
          label: 'Боломжит их',
          value: 'availableCountMuch'
        }, {
          label: 'Боломжит бага',
          value: 'availableCountLow'
        }, {
          label: 'Хүлээгдэж буй их',
          value: 'lockCountMuch'
        }, {
          label: 'Хүлээгдэж буй бага',
          value: 'lockCountLow'
        }, {
          label: 'Агуулахад байхгүй ч зарах',
          value: 'sellCheck'
        }
      ]
    }
  },
  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getInventory(from, size, this.search, this.field, this.sort)
  },
  methods: {
    onSearch () {
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'stockDetail',
          params: {
            variant_id: row.variant_id
          }
        }
      ).catch(() => {})
    },
    maxMin () {
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, maxi: this.maxi, mini: this.mini } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort, this.maxi, this.mini)
    },
    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'variant_name',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'variant_name',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        availableCountMuch: {
          field: 'available_count',
          sort: 'desc',
          sortName: 'Боломжит их'
        },
        availableCountLow: {
          field: 'available_count',
          sort: 'asc',
          sortName: 'Боломжит бага'
        },
        lockCountMuch: {
          field: 'lock_count',
          sort: 'desc',
          sortName: 'Хүлээгдэж буй их'
        },
        lockCountLow: {
          field: 'lock_count',
          sort: 'asc',
          sortName: 'Хүлээгдэж буй бага'
        },
        sellCheck: {
          field: 'sell_check',
          sort: 'true',
          sortName: 'Агуулахад байхгүй ч зарах'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    handleSortTable (data) {
      if (data.column.label === 'Бүтээгдэхүүн' && data.order === 'ascending') {
        this.field = 'variant_name'
        this.sort = 'asc'
      } else if (data.column.label === 'Бүтээгдэхүүн' && data.order === 'descending') {
        this.field = 'variant_name'
        this.sort = 'desc'
      } else if (data.column.label === 'Дууссанч зарах' && data.order === 'ascending') {
        this.field = 'sell_check'
        this.sort = 'true'
      } else if (data.column.label === 'Дууссанч зарах' && data.order === 'descending') {
        this.field = 'sell_check'
        this.sort = 'false'
      } else if (data.column.label === 'Боломжит' && data.order === 'ascending') {
        this.field = 'available_count'
        this.sort = 'desc'
      } else if (data.column.label === 'Боломжит' && data.order === 'descending') {
        this.field = 'available_count'
        this.sort = 'asc'
      } else if (data.column.label === 'Хүлээгдэж буй' && data.order === 'ascending') {
        this.field = 'lock_count'
        this.sort = 'desc'
      } else if (data.column.label === 'Хүлээгдэж буй' && data.order === 'descending') {
        this.field = 'lock_count'
        this.sort = 'asc'
      }
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getInventory((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    mergeValues (data) {
      var tempData = ''
      data.forEach((element, index) => {
        tempData = tempData + (index === 0 ? '' : ' / ') + element.value
      })
      return tempData
    },

    getInventory (from, size, search, field, sort) {
      this.isLoading = true
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort + '&max=' + this.maxi + '&min=' + this.mini
      services.getInventory(query).then(data => {
        this.expiredTotal = data.expired_total
        if (data.data) {
          data.data.forEach(element => {
            element.quantityChangeType = 'Add'
            element.variant_name = element.variant_name ? element.variant_name : ''
          })
          this.tableData = data.data
          this.totalCount = data.total
        }
        this.isLoading = false
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
